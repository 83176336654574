<template>
  <div class="page">
    <el-container>
      <el-aside class="nav" width="200px">
        <div class="header">
          <div class="logo">
            <img width="34" height="34" src="@/assets/logo.png" alt="" />
          </div>
          <el-divider class="vcut" direction="vertical"></el-divider>
          <div class="desc">
            <span>个人中心</span>
          </div>
        </div>
        <!-- <div class="menu">
          <div
            class="item"
            :class="
              crouter === '/personalCenter/accountSetting' ? 'active' : ''
            "
            @click="modulechange('/personalCenter/accountSetting')"
          >
            <span>账户设置</span>
          </div>
          <div
            class="item"
            :class="crouter === '/personalCenter/pluginSetting' ? 'active' : ''"
            @click="modulechange('/personalCenter/pluginSetting')"
          >
            <span>插件设置</span>
          </div>
          <div
            class="item"
            :class="
              crouter === '/personalCenter/packagePlanning' ? 'active' : ''
            "
            @click="modulechange('/personalCenter/packagePlanning')"
          >
            <span>套餐计划</span>
          </div>
          <div
            class="item"
            :class="
              crouter === '/personalCenter/integralInformation' ? 'active' : ''
            "
            @click="modulechange('/personalCenter/integralInformation')"
          >
            <span>积分信息</span>
          </div>
          <div
            class="item"
            :class="crouter === '/personalCenter/messageRecord' ? 'active' : ''"
            @click="modulechange('/personalCenter/messageRecord')"
          >
            <span>消息记录</span>
          </div>
          <div
            class="item"
            :class="
              crouter === '/personalCenter/BenchmarkStoreRecommendation'
                ? 'active'
                : ''
            "
            @click="
              modulechange('/personalCenter/BenchmarkStoreRecommendation')
            "
          >
            <span>标杆店铺推荐</span>
          </div>
        </div> -->

        <el-menu background-color="#F8F8F8">
          <el-menu-item
            index="1"
            class="item"
            :class="
              crouter === '/personalCenter/accountSetting' ? 'active' : ''
            "
            @click="modulechange('/personalCenter/accountSetting')"
          >
            <i class="el-icon-user"></i>
            <span>账户设置</span>
          </el-menu-item>
          <el-menu-item
            index="2"
            class="item"
            :class="crouter === '/personalCenter/pluginSetting' ? 'active' : ''"
            @click="modulechange('/personalCenter/pluginSetting')"
          >
            <i class="el-icon-setting"></i>
            <span>插件设置</span>
          </el-menu-item>
          <!-- <el-menu-item
            index="3"
            class="item"
            :class="
              crouter === '/personalCenter/packagePlanning' ? 'active' : ''
            "
            @click="modulechange('/personalCenter/packagePlanning')"
          >
            <i class="el-icon-document"></i>
            <span>套餐计划</span>
          </el-menu-item> -->
          <el-menu-item
            index="4"
            class="item"
            :class="
              crouter === '/personalCenter/integralInformation' ? 'active' : ''
            "
            @click="modulechange('/personalCenter/integralInformation')"
          >
            <i class="el-icon-message"></i>
            <span>积分信息</span>
          </el-menu-item>
          <el-menu-item
            index="6"
            class="item"
            :class="crouter === '/personalCenter/invitedUsers' ? 'active' : ''"
            @click="modulechange('/personalCenter/invitedUsers')"
          >
            <i class="el-icon-view"></i>
            <span>城市合伙人</span>
          </el-menu-item>
          <el-menu-item
            index="5"
            class="item"
            :class="crouter === '/personalCenter/messageRecord' ? 'active' : ''"
            @click="modulechange('/personalCenter/messageRecord')"
          >
            <i class="el-icon-chat-dot-round"></i>
            <span>消息记录</span>
          </el-menu-item>
          <!-- <el-submenu index="6">
            <template slot="title">
              <i class="el-icon-wind-power"></i>
              <span>学标杆</span>
            </template>
            <el-menu-item
              index="6-1"
              class="item"
              :class="
                crouter === '/personalCenter/BenchmarkStoreRecommendation'
                  ? 'active'
                  : ''
              "
              @click="
                modulechange('/personalCenter/BenchmarkStoreRecommendation')
              "
              ><span>标杆店铺推荐</span></el-menu-item
            >
          </el-submenu> -->
        </el-menu>
      </el-aside>
      <el-container>
        <el-main class="main">
          <router-view />
        </el-main>
        <el-footer class="footer">
          <div class="item">
            <span>使用条款</span>
          </div>
          <el-divider class="vcut" direction="vertical"></el-divider>
          <div class="item">
            <span>隐私政策</span>
          </div>
          <el-divider class="vcut" direction="vertical"></el-divider>
          <div class="item">
            <span>联系客服</span>
          </div>
          <el-divider class="vcut" direction="vertical"></el-divider>
          <div class="item">
            <span>©来搜</span>
          </div>
        </el-footer>
      </el-container>
    </el-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      crouter: "",
    };
  },
  mounted() {
    this.crouter = this.$route.path;
  },
  methods: {
    modulechange(p) {
      this.crouter = p;
      this.$router.push(p);
    },
  },
};
</script>

<style scoped>
.page {
  overflow: hidden;
}

.nav {
  user-select: none;
  height: 100vh;
  overflow: hidden;
}

.header {
  height: 70px;
  display: flex;
  align-items: center;
}

.header .logo {
  padding: 0 20px;
}

.header .vcut {
  height: 24px;
  margin: 0;
}

.header .desc {
  margin-left: 20px;
  font-size: 14px;
}
.item {
  font-size: 13px;
}

/* .menu .item {
  font-size: 13px;
  color: #515151;
  display: flex;
  height: 46px;
  align-items: center;
  padding-left: 30px;
}

.menu .item:hover {
  cursor: pointer;
  background-color: #eee;
} */

/* .menu .item:hover span {
  background: rgba(0, 0, 0, 0);
} */

::v-deep .el-menu-item.active {
  color: #ff6a00;
}

.main {
  height: calc(100vh - 60px);
}

.footer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  min-width: 450px;
  overflow: hidden;
}

.footer .item {
  padding: 0 10px;
  color: #999;
}

.footer .item:hover {
  cursor: pointer;
}
::v-deep .el-menu {
  border-right: 0;
}

::v-deep .el-menu-item:hover {
  background-color: #eee !important;
}

/* ::v-deep .el-submenu .el-menu-item:hover{
  background-color: #eee !important;
} */
</style>